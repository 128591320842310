<template>
  <v-content>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>

    <v-fab-transition>
      <v-btn
              fixed
              dark
              fab
              bottom
              right
              color="#df0621"
              v-show="isScrolling"
              v-scroll="onScroll"
              @click="$vuetify.goTo(0)"
            >
              <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>

  </v-content>
</template>

<script>
  export default {
    metaInfo () {
      return {
        titleTemplate: 'Van Romaine'
      }
    },
    data () {
     return {
       offsetTop: 0,
       isScrolling: false
      }
    },
    computed: {
      options () {
        return {
          duration: 500,
          offset: -40,
          easing: 'easeInOutCubic'
        }
     }
    },
    methods: {
      onScroll () {
        this.isScrolling = (window.pageYOffset ||
          document.documentElement.scrollTop || 0) > 100
      }
    }
  }
</script>
