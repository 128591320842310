<template>
  <alpha-footer
    :company="$t('Layout.Footer.company')"
    facebook="https://www.facebook.com/van.romaine"
    instagram="https://www.instagram.com/van.romaine"
    :categories="categories"
  >
    <span>
      &copy; 2021
      {{ $t('Layout.Footer.company') }}
      &mdash;
      {{ $t('Layout.Footer.rights') }}
    </span>
  </alpha-footer>
</template>

<script>
  export default {
    computed: {
      categories () {
        return this.$t('Layout.Footer.categories')
      }
    }
  }
</script>
