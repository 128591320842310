 <template>
   <v-dialog
      v-model="dialog"
      width="500"
    >
      <a
        slot="activator"
      >
        {{item.name}}
      </a>

      <v-card>
        <v-card-text>
          {{item.desc}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            flat
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
 </v-dialog>
</template>
<script>
  export default {
    props: {
      item: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        dialog: false
      }
    }
  }
</script>