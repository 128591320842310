<template>
  <v-img
    height="40vh"
    src="/static/background7secs.jpg"
    gradient="to top, rgba(255, 255, 255, .75), rgba(255, 255, 255, .75)"
  >
    <v-fade-transition mode="out-in">
      <v-container
        fill-height
        :key="$route.path"
        v-if="isBooted"
      >
        <v-layout align-center>
          <v-fade-transition mode="out-in">
            <v-flex
              text-xs-center
              :key="$route.path"
            >
              <h1 class="display-2" v-html="title" />
              <div
                class="subheading"
                v-html="subTitle"
                v-if="subTitle"
              />
            </v-flex>
          </v-fade-transition>
        </v-layout>
      </v-container>
    </v-fade-transition>
  </v-img>
</template>

<script>
  export default {
    data: () => ({
      isBooted: false
    }),

    computed: {
      namespace () {
        return this.$route.name
      },
      title () {
        return this.$t('Views.Home.title')
      },
      subTitle () {
        return this.$t('Views.Home.subTitle')
      }
    },

    mounted () {
      setTimeout(() => {
        this.isBooted = true
      }, 200)
    }
  }
</script>
