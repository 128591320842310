<template>
  <v-container grid-list-xl pa-0>
    <v-layout
      row
      wrap
      justify-center
    >
      <v-flex
        xs12
        sm6
        md4
        d-flex
        v-for="feature in features"
        :key="feature.id"
      >
        <v-card flat tile>
          <v-img
            :src="`/static/releases/release-${feature.id}.jpg`"
            :width="cardHeight"
          />
          <v-card-text v-html="featureText(feature)" />
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    name: 'alpha-card-feature',

    props: {
      cardHeight: {
        type: String,
        default: '195px'
      },
      features: {
        type: Array,
        default: () => ([])
      }
    },
    methods: {
      featureText (feature) {
        return feature.title + '<br><strong>' + feature.artist + '</strong>'
      }
    }
  }
</script>
