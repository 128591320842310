<template>
  <v-navigation-drawer
    fixed
    temporary
    v-model="inputValue"
  >
    <v-list>
      <v-list-tile
        v-for="(item, i) in items"
        :key="i"
        @click="$vuetify.goTo(item.href, {
          offset: -100
        }), inputValue = false"
      >
        <v-list-tile-title class="vlist-size" v-text="item.text" />
      </v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { mapMutations } from 'vuex'

  export default {
    computed: {
      inputValue: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.setDrawer(val)
        }
      },
      items () {
        return this.$t('Layout.View.items')
      }
    },

    methods: {
      ...mapMutations('app', ['setDrawer', 'toggleDrawer'])
    }
  }
</script>

<style>
  .vlist-size {
    font-size: 22px;
  }
</style>
