<template>
  <v-toolbar
    app
    :flat="!isScrolling"
    :color="!isScrolling ? 'transparent' : '#37474f'"
    v-scroll="onScroll"
  >
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-spacer />
      <v-btn
        :active-class="!isScrolling ? 'primary--text' : undefined"
        flat
        v-for="(item, i) in items"
        :key="i"
        :href="item.href"
        @click="$vuetify.goTo(item.href, {
          offset: 0
        })"
      >
        <span class="white--text title font-weight-black" v-text="item.text" />
      </v-btn>
      <v-spacer />
    </template>
    <v-btn v-else icon @click="toggleDrawer">
      <v-icon large class="white--text">mdi-menu</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
  // Utilities
  import { mapMutations } from 'vuex'

  export default {
    data: () => ({
      isScrolling: false
    }),

    computed: {
      items () {
        return this.$t('Layout.View.items')
      }
    },

    methods: {
      ...mapMutations('app', ['toggleDrawer']),
      onScroll () {
        this.isScrolling = (window.pageYOffset ||
          document.documentElement.scrollTop || 0) > 100
      }
    }
  }
</script>
